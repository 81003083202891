import { Component, Input, OnInit } from '@angular/core';
import { faChevronRight, faChevronLeft, faBed, faBath} from '@fortawesome/free-solid-svg-icons';
import { SwiperComponent } from "swiper/angular";
import SwiperCore, {Navigation, Pagination, Swiper} from 'swiper/core';

@Component({
  selector: 'fp-property-item',
  templateUrl: './property-item.component.html',
  styleUrls: ['./property-item.component.scss']
})
export class PropertyItemComponent implements OnInit {
  
  @Input() property;
  
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  faBed = faBed;
  faBath = faBath;

  constructor() { }

  ngOnInit(): void {
  }
  

}
