import { Component, OnInit } from '@angular/core';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'fp-home-contact',
  templateUrl: './home-contact.component.html',
  styleUrls: ['./home-contact.component.scss']
})
export class HomeContactComponent implements OnInit {

  faLongArrowAltRight = faLongArrowAltRight;
  
  constructor() { }

  ngOnInit(): void {
  }

}
