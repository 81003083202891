import { Component, OnInit } from '@angular/core';
import  categoriesJson  from  'src/app/shared/jsons/categories.json';
@Component({
  selector: 'fp-home-category',
  templateUrl: './home-category.component.html',
  styleUrls: ['./home-category.component.scss']
})
export class HomeCategoryComponent implements OnInit {

  categories: {} = categoriesJson;

  constructor() { }

  ngOnInit(): void {
  }

}
