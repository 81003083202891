<section class="home-banner">
    <swiper
        [slidesPerView]="1"
        [spaceBetween]="0"
        [navigation]="true"
        [pagination]="{ clickable: true }"
        >
          <ng-template swiperSlide>
              <img src="https://enerbi.co.id/wp-content/uploads/2017/12/Ten-Renowned-Green-Buildings-in-India-1000x455.jpg" alt="">
          </ng-template>
          <ng-template swiperSlide>
              <img src="https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHw%3D&w=1000&q=80" alt="">
            </ng-template>
          <ng-template swiperSlide>
              <img src="https://static.wixstatic.com/media/16c592_af9b87d4dab84e29b0c6faf04932636d~mv2_d_2975_2081_s_2.jpg" alt="">
          </ng-template>
    </swiper>
        <!-- <button class="car-btn-dark c-swiper-prev btn"><fa-icon [icon]="faChevronLeft"></fa-icon></button>
        <button class="car-btn-dark c-swiper-next btn"><fa-icon [icon]="faChevronRight"></fa-icon></button> -->
    <div class="home-banner-wrapper wrapper" [routerLink]="['/property/detail']">
        <a class="banner-prop-card box-shadow-30">
        <div class="prop-header">
            <span class="title txt-secondary">House for Rent</span>
            <span class="viewmore link-s-main">View More <fa-icon [icon]="faLongArrowAltRight"></fa-icon></span>
        </div>
        <div class="prop-content">
            <h3 class="title txt-dark">Midtown Modern</h3>
            <address class="txt-grey capitalize">tan quee lan street d07</address>
        </div>
        <div class="prop-footer">
            <p class="price txt-dark">$ 1,1 M</p>
            <ul class="prop-info list-inline">
                <li class="list-inline-item">
                    <span>3</span>
                    <fa-icon [icon]="faBed"></fa-icon>
                </li>
                <li class="list-inline-item">
                    <span>3</span>
                    <fa-icon [icon]="faBath"></fa-icon>
                </li>
                <li class="list-inline-item">
                    <span>1,097 sqft</span>
                </li>
            </ul>
        </div>
        </a>
    </div>
</section>