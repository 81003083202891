import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/components/header/header.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SwiperModule } from 'swiper/angular';
import { HomeComponent } from './pages/home/home.component';
import { PropertyItemComponent } from './shared/property-item/property-item.component';
import { HomeBannerComponent } from './pages/home/components/home-banner/home-banner.component';
import { HomeSearchComponent } from './pages/home/components/home-search/home-search.component';
import { HomeCategoryComponent } from './pages/home/components/home-category/home-category.component';
import { HomeInfoComponent } from './pages/home/components/home-info/home-info.component';
import { HomeCalculatorComponent } from './pages/home/components/home-calculator/home-calculator.component';
import { HomeArticlesComponent } from './pages/home/components/home-articles/home-articles.component';
import { HomeContactComponent } from './pages/home/components/home-contact/home-contact.component';
import { HomeListingComponent } from './pages/home/components/home-listing/home-listing.component';
import { PropertyCardComponent } from './shared/property-card/property-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PropertyDetailComponent } from './pages/property/property-detail/property-detail.component';
import { PropertyListComponent } from './pages/property/property-list/property-list.component';
import { PropertyDetailBannerComponent } from './pages/property/property-detail/components/property-detail-banner/property-detail-banner.component';
import { PropertyDetailMainComponent } from './pages/property/property-detail/components/property-detail-main/property-detail-main.component';
import { PropertyDetailAsideComponent } from './pages/property/property-detail/components/property-detail-aside/property-detail-aside.component';
import { DropdownComponent } from './utilities/dropdown/dropdown.component';
import { BreadcrumbComponent } from './utilities/breadcrumb/breadcrumb.component';
import { CityPipe } from './core/pipes/city.pipe';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchFormComponent } from './shared/search-form/search-form.component';
import { FilterFormComponent } from './shared/filter-form/filter-form.component';
import { ContactUsComponent } from './pages/company/contact-us/contact-us.component';
import { AboutComponent } from './pages/company/about/about.component';
import { AgentListComponent } from './pages/company/agent/agent-list/agent-list.component';
import { AgentDetailComponent } from './pages/company/agent/agent-detail/agent-detail.component';
import { NewsListComponent } from './pages/news/news-list/news-list.component';
import { NewsDetailComponent } from './pages/news/news-detail/news-detail.component';
import { LegalComponent } from './pages/company/legal/legal.component';
import { FaqComponent } from './pages/company/faq/faq.component';
import { ScrollTopComponent } from './core/components/scroll-top/scroll-top.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PropertyItemComponent,
    HomeBannerComponent,
    HomeSearchComponent,
    HomeCategoryComponent,
    HomeInfoComponent,
    HomeCalculatorComponent,
    HomeArticlesComponent,
    HomeContactComponent,
    HomeListingComponent,
    PropertyCardComponent,
    PropertyDetailComponent,
    PropertyListComponent,
    PropertyDetailBannerComponent,
    PropertyDetailMainComponent,
    PropertyDetailAsideComponent,
    DropdownComponent,
    BreadcrumbComponent,
    CityPipe,
    SearchFormComponent,
    FilterFormComponent,
    ContactUsComponent,
    AboutComponent,
    AgentListComponent,
    AgentDetailComponent,
    NewsListComponent,
    NewsDetailComponent,
    LegalComponent,
    FaqComponent,
    ScrollTopComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FontAwesomeModule,
    SwiperModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
