import { Component, OnInit } from '@angular/core';
import SwiperCore from 'swiper/core';
import {faMapMarkerAlt, faBed, faBath, faVectorSquare, faCar, faTv, faSwimmingPool, faDumbbell, faCouch, faCity, faSchool, faBus, faTrain, faWalking} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'fp-property-detail-main',
  templateUrl: './property-detail-main.component.html',
  styleUrls: ['./property-detail-main.component.scss']
})
export class PropertyDetailMainComponent implements OnInit {

  faMapMarkerAlt = faMapMarkerAlt;
  faBed = faBed;
  faBath = faBath;
  faVectorSquare = faVectorSquare;
  faCar = faCar;
  faTv = faTv;
  faSwimmingPool = faSwimmingPool;
  faDumbbell = faDumbbell;
  faCouch = faCouch;

  faCity = faCity;
  faSchool = faSchool;
  faBus = faBus;
  faTrain = faTrain;

  selectedIndex = 0;
  
  tabs=[
    {
      icon: faCity,
      name:"public places"
    },
    {
      icon: faSchool,
      name:"school"
    },
    {
      icon: faBus,
      name:"bus stop"
    },
    {
      icon: faTrain,
      name:"MRT station"
    },
    {
      icon: faTrain,
      name:"Train station"
    },
  ]

  neighborhoods = [{
    id:1,
    type:"Public Places",
    locations: [
      {
        id:1,
        place:"Grand City Park",
        transport:faWalking,
        duration:"10 min",
        distance:"0.5 mi"
      },
      {
        id:2,
        place:"Vivo Shopping Centre",
        transport:faWalking,
        duration:"12 min",
        distance:"0.6 mi"
      },
      {
        id:3,
        place:"Changi Airport",
        transport:faCar,
        duration:"15 min",
        distance:"1.2 mi"
      },
    ]
  }]

  locations= [
    {
      id:1,
      place:"Grand City Park",
      transport:faWalking,
      duration:"10 min",
      distance:"0.5 mi"
    },
    {
      id:2,
      place:"Vivo Shopping Centre",
      transport:faWalking,
      duration:"12 min",
      distance:"0.6 mi"
    },
    {
      id:3,
      place:"Changi Airport",
      transport:faCar,
      duration:"15 min",
      distance:"1.2 mi"
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

  onSwiper(swiper) {
    // console.log(swiper);
  }

  seeTable(index) {
    this.selectedIndex = index;
  }
}
