import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CityService {
  apiUrl = "https://countriesnow.space/api/v0.1/countries/cities"; 

  constructor(private http: HttpClient) {}

  getCities() {
    let param = {
      "country" : 'singapore'
    }
    return this.http.post(this.apiUrl,param);
  }
}
