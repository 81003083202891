import { Component, OnInit } from '@angular/core';
import { faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faLongArrowAltRight, faPhone, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import  agentsJson from  'src/app/shared/jsons/agents.json';

@Component({
  selector: 'fp-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.scss']
})
export class AgentListComponent implements OnInit {

  agents = agentsJson;
  faLongArrowAltRight = faLongArrowAltRight;
  faWhatsapp = faWhatsapp;
  faEnvelope = faEnvelope;
  faPhoneAlt = faPhoneAlt;
  faLinkedin = faLinkedin;

  constructor() { }

  ngOnInit(): void {
  }

}
