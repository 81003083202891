import { Component, OnInit } from '@angular/core';
import { faBuilding, faThumbsUp, faComments, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
@Component({
  selector: 'fp-home-info',
  templateUrl: './home-info.component.html',
  styleUrls: ['./home-info.component.scss'],
  animations:[
    trigger('toggleSwitch', [
      // ...
      state('stateOne', style({
        left: 0,
      })),
      state('stateTwo', style({
        left: '50%',
      })),
      transition('stateTwo <=> stateOne', [
        animate('0.5s')
      ]),
    ])
  ]
})
export class HomeInfoComponent implements OnInit {

  isStateOne:boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  milestones = [
    {
      title:"years of services",
      total:"15"
    },
    {
      title:"properties",
      total:"100k+"
    },
    {
      title:"expert agents",
      total:"100+"
    },
    {
      title:"satisfied customers",
      total:"50k+"
    },
  ]

  infos = [
    {
      icon: faBuilding,
      title:"Nunc dignissim eu nec.",
      description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra in imperdiet pulvinar. Id amet, congue sed a nibh amet, turpis. Sagittis a eu cras."
    },
    {
      icon: faThumbsUp,
      title:"Nunc dignissim eu nec.",
      description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra in imperdiet pulvinar. Id amet, congue sed a nibh amet, turpis. Sagittis a eu cras."
    },
    {
      icon: faComments,
      title:"Nunc dignissim eu nec.",
      description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra in imperdiet pulvinar. Id amet, congue sed a nibh amet, turpis. Sagittis a eu cras."
    },
    {
      icon: faShieldAlt,
      title:"Nunc dignissim eu nec.",
      description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra in imperdiet pulvinar. Id amet, congue sed a nibh amet, turpis. Sagittis a eu cras."
    }
  ]

  manual =  {
    id:1,
    title:"how to buy or rent house",
    image:"assets/bg/for-tenant.svg",
    lists:[
      {
        item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Accumsan varius scelerisque felis massa elementum euismod."
      },
      {
        item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu odio ipsum."
      },
      {
        item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu odio ipsum."
      }
    ]
  }

  changeState(state) {
    if(state == 1) {
      this.isStateOne = true;
      this.manual = {
        id:1,
        title:"how to buy or rent house",
        image:"assets/bg/for-tenant.svg",
        lists:[
          {
            item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Accumsan varius scelerisque felis massa elementum euismod."
          },
          {
            item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu odio ipsum."
          },
          {
            item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu odio ipsum."
          }
        ]
      } 
    } else {
      this.isStateOne = false;
      this.manual = {
        id:2,
        title:"how to sell or rent house",
        image:"assets/bg/for-landlord.svg",
        lists:[
          {
            item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Accumsan varius scelerisque felis massa elementum euismod."
          },
          {
            item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu odio ipsum."
          },
          {
            item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu odio ipsum."
          },
          {
            item:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu odio ipsum."
          }
        ]
      }
    }
  }

}
