import { AfterViewInit, Component, ElementRef, HostListener, OnChanges, OnInit, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'fp-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.scss'],
  animations:[
    trigger('showHide', [
      state('show', style({
        position: 'fixed',
        bottom: 0,
      })),
      state('hide', style({
        position:'fixed',
        bottom: '-200px',
      })),
      transition('show <=> hide', [
        animate('.3s')
      ]),
    ])
  ]
})
export class PropertyDetailComponent implements OnInit, AfterViewInit {

  @ViewChild('propertyAside') propAsideElem: ElementRef;

  elemYOffset: number;
  elemHeight: number;
  isShow = true;
  innerHeight;
  faPhoneAlt = faPhoneAlt;

  constructor() { 
  }
  
  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.elemYOffset = this.propAsideElem.nativeElement.offsetTop;
    this.elemHeight = this.propAsideElem.nativeElement.offsetHeight;
  }
  // ngOnChanges() {
  //   this.onResize();
  // }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerHeight = window.innerHeight;
    this.ngAfterViewInit();
  }
  @HostListener('window:scroll', ['$event'])
  windowOnScroll(e){
    // console.log(window.pageYOffset);
    // this.innerHeight = window.innerHeight;
    // let calcHeight = document.querySelector('.calculators').clientHeight;
    if (window.pageYOffset < this.elemYOffset-innerHeight-100) {
       this.isShow = true;
      } else {
        this.isShow = false;
      }
      console.log(innerHeight)
    }
}
