<section class="faq">
    <div class="wrapper">
        <div class="container">
            <h1>Frequently Asked Questions</h1>
            <hr>
            <p class="mb-5">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi rem commodi libero ipsam mollitia deserunt quis unde eaque sint totam, facilis saepe nemo consectetur quasi delectus nulla magnam, eius adipisci?</p>
            <div class="accordion" id="accordionFaq">
                <div class="card" *ngFor="let faq of faqs">
                  <div class="card-header" id="heading{{faq.id}}">
                    <h2 class="mb-0">
                      <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" 
                      attr.data-target="#collapse{{faq.id}}"
                      aria-expanded="true" attr.aria-controls="collapse{{faq.id}}" (click)="toggleIcon()">
                        <div class="d-flex justify-content-between align-items-center">
                            <span>{{faq.question}}</span>
                            <fa-icon class="txt-primary" [icon]="icon"></fa-icon>
                        </div>
                      </button>
                    </h2>
                  </div>
              
                  <div id="collapse{{faq.id}}" class="collapse" aria-labelledby="heading{{faq.id}}" data-parent="#accordionFaq">
                    <div class="card-body">
                      {{faq.answer}}
                    </div>
                  </div>
                </div>
              </div>
        </div>
    </div>
</section>