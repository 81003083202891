<button 
  class="btn dropdown-toggle" 
  type="button" 
  data-toggle="dropdown" 
  aria-haspopup="true" 
  aria-expanded="false" 
  [innerHTML]="(selected) ?  selectedValue : (option.type)"
  [ngClass]="(selected) ? 'txt-secondary ' +(customClass) : customClass"
  >
 
</button>
<div class="dropdown-menu" aria-labelledby="dropdownmenu">
  <option class="dropdown-item" type="button" value="0" (click)="selectOption('All '+option.type)">
    All {{option.type}}</option>
  <option class="dropdown-item" type="button" *ngFor="let optionList of option.list" value="{{optionList.value}}" (click)="selectOption(optionList.name)">
    {{optionList.name}}</option>
</div>
