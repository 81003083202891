import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import  propertiesJson  from  'src/app/shared/jsons/properties.json';
import  searchOptionsJson  from  'src/app/shared/jsons/searchOptions.json';

@Component({
  selector: 'fp-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.scss']
})
export class PropertyListComponent implements OnInit {

  // @ViewChild('swiperListing') swiperListing: any;
  @ViewChild('stickyFilter') stickyFilter: ElementRef;
  
  faSearch = faSearch;

  config: any = {
    slidesPerView: 4,
    spaceBetween: 20,
  }

  properties = propertiesJson;

  additionalSearchOptions = searchOptionsJson;

  elemYOffset;

  @HostListener('window:scroll', ['$event'])

  windowOnScroll(e) {
      let element = document.querySelector('.sticky-filter') as HTMLElement;
      // console.log(this.elemYOffset);
      if (window.pageYOffset > this.elemYOffset) {
        element.classList.add('on-scroll');
      } else {
        element.classList.remove('on-scroll');
      }
    }

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.elemYOffset = this.stickyFilter.nativeElement.offsetTop;
  }

}
