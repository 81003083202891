<div class="property-item">
    <swiper
        [slidesPerView]="1"
        [spaceBetween]="0"
        [navigation]="true"
        [pagination]="{ clickable: true }"
        >
        <ng-container *ngFor="let prop of property.images">
            <ng-template swiperSlide>
                <img class="property-item-img"  [src]="prop.path" [alt]="prop.alt">
            </ng-template>
        </ng-container>
    </swiper>
   <div class="property-item-info">
    <a [routerLink]="['/property/detail']" class="property-card">
        <div class="prop-header">
            <span class="title txt-secondary">House for {{property.status}}</span>
        </div>
        <div class="prop-content">
            <h4 class="title txt-dark"><small class="title">{{property.name}}</small></h4>
            <address class="txt-grey capitalize">{{property.address}}</address>
        </div>
        <div class="prop-footer">
            <p class="price txt-dark">{{property.price}}</p>
            <ul class="prop-info list-inline">
                <li class="list-inline-item">
                    <span>{{property.bedroom}}</span>
                    <fa-icon [icon]="faBed"></fa-icon>
                </li>
                <li class="list-inline-item">
                    <span>{{property.bathroom}}</span>
                    <fa-icon [icon]="faBath"></fa-icon>
                </li>
                <li class="list-inline-item">
                    <span>{{property.area}}</span>
                </li>
            </ul>
        </div>
        </a>
   </div>
</div>