<section class="prop-detail-info">
    <fp-breadcrumb></fp-breadcrumb>
    <div class="badge">
        
    </div>
    <div class="prop-detail-heading">
        <h1>3 Bed Condo for Sale in Central Grove</h1>
        <address class="txt-p-lighter"><fa-icon [icon]="faMapMarkerAlt"></fa-icon> 1 Geylang East Avenue 1 - Eunos / Geylang / Paya Lebar (D14), Singapore</address>
        <div class="info-badge-group">
            <span class="info-badge"><fa-icon [icon]="faBed"></fa-icon>3 Bedrooms</span>
            <span class="info-badge"><fa-icon [icon]="faBath"></fa-icon>2 Bathrooms</span>
            <span class="info-badge"><fa-icon [icon]="faVectorSquare"></fa-icon>5,876 sqft</span>
        </div>
    </div>
    <div class="prop-detail-content">
        <div class="detail-description info-margin">
            <h3 class="title">Description</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget diam in scelerisque nibh. Amet eget malesuada et sapien arcu placerat vulputate enim. Lorem sit sed viverra mattis risus justo, in pellentesque odio. Nibh convallis ut ornare lorem enim at ultrices scelerisque. Magna praesent ut sed augue commodo vitae ullamcorper. Lorem a tellus quam mauris nisl. Enim mauris enim mauris rhoncus, vulputate ac tortor. Massa sit orci faucibus ultricies quis cursus. Augue elit id libero at.</p>
            <div class="show-more">
                <a href="javascript:void(0)" class="link-s-main">Show More</a>
            </div>
        </div>
        <div class="detail-amenities info-margin">
            <h3 class="title">Amenities</h3>
            <div class="amenities">
                <div class="amenity"><fa-icon [icon]="faBed"></fa-icon>Extra Bed</div>
                <div class="amenity"><fa-icon [icon]="faBath"></fa-icon>Bath Tub</div>
                <div class="amenity"><fa-icon [icon]="faCar"></fa-icon>Parking Lot</div>
                <div class="amenity"><fa-icon [icon]="faTv"></fa-icon>TV</div>
                <div class="amenity"><fa-icon [icon]="faSwimmingPool"></fa-icon>Swimming Pool</div>
                <div class="amenity"><fa-icon [icon]="faDumbbell"></fa-icon>Gym</div>
                <div class="amenity"><fa-icon [icon]="faCouch"></fa-icon>Sofa</div>
            </div>
            <div class="show-more">
                <a href="javascript:void(0)" class="link-s-main">Show More</a>
            </div>
        </div>
        <div class="detail-neighborhood info-margin pb-0">
            <div class="row">
                <div class="col-xl-4">
                    <h3 class="title">neighborhood</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget diam in scelerisque nibh. Amet eget malesuada et sapien arcu placerat vulputate enim. Lorem sit sed viverra mattis risus justo, in pellentesque odio. Nibh convallis ut ornare lorem enim at ultrices scelerisque. Magna praesent ut sed augue commodo vitae ullamcorper.</p>

                </div>
                <div class="col-xl-8">
                    <div class="location-tabs-wrapper">
                        <swiper
                            [slidesPerView]="4"
                            [spaceBetween]="20"
                            [navigation]="true"
                            (swiper)="onSwiper($event)"
                            >
                            <ng-template swiperSlide *ngFor="let tab of tabs; let i = index">
                                <span class="tab-icon click" (click)="seeTable(i)" [ngClass]="(i == selectedIndex)? 'active' : ''">
                                    <fa-icon [icon]="tab.icon"></fa-icon>
                                    <p>{{tab.name}}</p>
                                </span>
                            </ng-template>
                        </swiper>
                        <table class="table">
                            <thead>
                              <th>
                                Places
                              </th>
                              <th colspan="2">Distance</th>
                            </thead>
                            <tbody>
                              <tr *ngFor="let location of locations">
                                <td>{{location.place}}</td>
                                <td><fa-icon [icon]="location.transport"></fa-icon> {{location.duration}}</td>
                                <td>{{location.distance}}</td>
                              </tr>
                            </tbody>
                          </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>