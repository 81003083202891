<section class="home-calculator container-fluid">
    <div class="wrapper">
        <div class="home-calculator-heading">
            <h1>Mortgage Calculator</h1>
            <p class="capitalize">Fill in your details in the calculator below to calculate your mortgage repayments.</p>
        </div>
        <div class="home-calculator-content">
            <div class="row">
                <div class="col-lg-4 col-12">
                    <form>
                        <!-- Property price -->
                        <div class="form-group prop-price row">
                          <label for="staticPropPrice" class="col-sm-4 col-form-label">Property Price</label>
                          <div class="col-sm-8">
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">$</div>
                                </div>
                                <input type="text" class="form-control" id="inlineFormInputGroup" placeholder="30,000" value="30000">
                                <div class="input-group-prepend">
                                  <div class="input-group-text end">SGD</div>
                                </div>
                            </div>
                          </div>
                        </div>

                        <!-- Tenure -->
                        <div class="form-group row">
                          <label for="staticTenure" class="col-sm-4 col-form-label">Tenure</label>
                          <div class="col-sm-8">
                            <div class="input-group mb-2">
                                <input type="number" class="form-control" id="inlineFormInputGroup" placeholder="30" value="30">
                                <div class="input-group-prepend">
                                  <div class="input-group-text end">YEARS</div>
                                </div>
                            </div>
                          </div>
                        </div>

                        <!-- Loan -->
                        <div class="form-group row">
                            <label for="staticLoan" class="col-sm-4 col-form-label">Loan</label>
                            <div class="col-sm-8">
                              <div class="input-group mb-2">
                                  <input type="number" class="form-control" id="inlineFormInputGroup" placeholder="75" value="75">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text end">%</div>
                                  </div>
                              </div>
                            </div>
                        </div>

                        <!-- Loan -->
                        <div class="form-group row">
                            <label for="staticInterest" class="col-sm-4 col-form-label">Interest Rates</label>
                            <div class="col-sm-8">
                              <div class="input-group mb-2">
                                  <input type="number" class="form-control" id="inlineFormInputGroup" placeholder="2" value="2">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text end">%</div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-lg-7 offset-lg-1">
                    <h5 class="mb-4">Mortgage Breakdown</h5>
                    <div class="chart-content">
                        <div class="chart">
                            <img src="/assets/chart.png" alt="mortgage chart">
                        </div>
                        <div class="chart-result">
                            <ul class="chart-lists">
                                <li>Vitae id odio donec eu quis nulla sed</li>
                                <li>Eget senectus sed et ipsum morbi</li>
                                <li>Consectetur fringilla integer nulla</li>
                            </ul>
                            <div class="chart-price">
                                <h5 class="title text-font">
                                    Monthly Payments Est.
                                </h5>
                                <p class="price">$ 831 <span>/month</span></p>
                                <a href="#" class="link-p-light">See Details <fa-icon [icon]="faLongArrowAltRight"></fa-icon></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-calculator-footer">
            <a href="#" type="button" class="btn out-s-main mr-4 mt-sm-0 mt-3">Affordability calculator</a>
            <a href="#" type="button" class="btn out-s-main mt-sm-0 mt-3">Progressive payments calculator</a>
        </div>
    </div>
</section>