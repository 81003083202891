import { Component, OnInit } from '@angular/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'fp-property-detail-aside',
  templateUrl: './property-detail-aside.component.html',
  styleUrls: ['./property-detail-aside.component.scss']
})
export class PropertyDetailAsideComponent implements OnInit {

  faChevronDown = faChevronDown;

  constructor() { }

  ngOnInit(): void {
  }

}
