import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'fp-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnInit {

  title;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    switch(this.route.snapshot['_routerState'].url) {
      case '/privacy-policy': {
        this.title="Privacy Policy";
        break;
      }
      case '/terms-and-condition': {
        this.title="Terms and Condition";
        break;
      }
    }

  }

}
