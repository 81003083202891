<header class="news-category-nav sticky-top">
    <div class="wrapper">
        <ul class="nav flex-nowrap align-items-end text-center" style="display: -webkit-box;">
            <li class="nav-item">
              <a class="nav-link active" routerLink="/news/list">All</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/news/list">Buying</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/news/list">Selling</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/news/list">Home Improvement</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/news/list">Financial</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/news/list">Tips & Guides</a>
            </li>
        </ul>
    </div>
</header>
<section routerLink="/news/detail" class="featured-articles container-fluid text-white click" style="background:linear-gradient(0deg, rgba(0,0,0,.5), rgba(0,0,0,0.5)), url('https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80') center no-repeat; background-size: cover;">
    <div class="wrapper">
        <h3 class="mt-5">Lorem ipsum, dolor sit amet consectetur adipisicing elit</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit vitae possimus totam animi, sint unde, impedit voluptatibus, tempore eum saepe natus perferendis obcaecati ...</p>
        <a class="btn-s-main mt-3 d-inline-block" routerLink="/news/detail">Read More</a>
    </div>
</section>
<section class="articles container-fluid">
    <div class="wrapper">
        <div class="article-list-item row">
            <!-- Headlines -->
            <div  routerLink="/news/detail" class="col-lg-4 col-sm-6 col-12 mb-3 order-lg-1 click">
                <div class="article-card align-items-sm-start align-items-center">
                    <img class="article-img" src="https://assets.weforum.org/article/image/large_15gAQdddPzO5Cn18bJ--4zQTUHrfpVxWQbAQ6jhvTSc.jpg" alt="">
                    <header>
                        <h5 class="title">Gravida aliquam vitae aliquam elementum</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl, in adipiscing morbi maecenas vel dolor amet, arcu augue. Neque pharetra consectetur nec libero vitae odio sem consequat. </p>
                        <time class="text-secondary d-sm-none d-block">Fri, 21 May 2021. 05:30PM</time>
                    </header>
                </div>
            </div>
            <!-- Headlines -->
            <div  routerLink="/news/detail" class="col-lg-4 col-sm-6 col-12 mb-3 order-lg-1 click">
                <div class="article-card align-items-sm-start align-items-center">
                    <img class="article-img" src="https://www.toronto-criminal-lawyer.co/wp-content/uploads/2019/09/Remediation-Agreement-672x372.jpeg" alt="">
                    <header>
                        <h5 class="title">Gravida aliquam vitae aliquam elementum</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl, in adipiscing morbi maecenas vel dolor amet, arcu augue. Neque pharetra consectetur nec libero vitae odio sem consequat. </p>
                        <time class="text-secondary d-sm-none d-block">Fri, 21 May 2021. 05:30PM</time>
                    </header>
                </div>
            </div>

            <!--Recommended Articles -->
            <div  routerLink="/news/detail" class="col-lg-4 col-12 mb-3 order-lg-2 order-3 click">
                <h5 class="mb-lg-3 mb-5 mt-lg-0 mt-5">Recommended For You</h5>
               <div class="article-item align-items-sm-start align-items-center">
                    <img class="article-img" src="https://images.theconversation.com/files/213762/original/file-20180409-176974-mulfwg.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=900.0&fit=crop" alt="">
                    <header>
                        <h5 class="title">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique a amet praesent.</h5>
                        <time class="text-secondary d-lg-none d-block">Fri, 21 May 2021. 05:30PM</time>
                    </header>
               </div>
               <div class="article-item align-items-sm-start align-items-center">
                    <img class="article-img" src="https://images.unsplash.com/photo-1501183638710-841dd1904471?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="">
                    <header>
                        <h5 class="title">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique a amet praesent.</h5>
                        <time class="text-secondary d-lg-none d-block">Fri, 21 May 2021. 05:30PM</time>
                    </header>
               </div>
               <div class="article-item align-items-sm-start align-items-center">
                    <img class="article-img" src="https://images.unsplash.com/photo-1449844908441-8829872d2607?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="">
                    <header>
                        <h5 class="title">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique a amet praesent.</h5>
                        <time class="text-secondary d-lg-none d-block">Fri, 21 May 2021. 05:30PM</time>
                    </header>
               </div>
            </div>
            <div class="col-12 my-xl-5 my-md-3 order-lg-3"></div>

            <!-- List Articles -->
            <div  routerLink="/news/detail" *ngFor="let article of articles" class="col-xl-3 col-md-4 col-sm-6 col-12 mb-3 order-lg-4 order-2 click">
                <div class="article-card">
                    <img class="article-img" [src]="article.image_path" [alt]="article.title">
                    <header>
                        <h5 class="title">{{article.title}}</h5>
                        <p>{{article.description}}</p>
                        <time class="text-secondary d-sm-none d-block">Fri, 21 May 2021. 05:30PM</time>
                    </header>
                </div>
            </div>
        </div>
        <div class="text-center">
            <button class="load-more btn btn-p-main">Load More</button>
        </div>
    </div>
</section>
