<section class="contact-form-wrapper container-fluid">
    <div class="row">
        <div class="col-lg-5 p-lg-0">
            <img src="/assets/bg/bg-contact.jpg" alt="" class="contact-img">
        </div>
        <div class="col-lg-4 offset-lg-2">
            <div class="custom-section">
                <div class="contact-heading text-center">
                    <h5 class="title">Contact Us</h5>
                    <p class="txt-p-light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus rhoncus, in id nunc malesuada. Aliquet ullamcorper sed vitae.</p>
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center">
                            <span class="line"></span>
                            <li class="page-item active"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <span class="line"></span>
                        </ul>
                    </nav>
                    <p class="txt-secondary">Fill in Your Personal Bio</p>
                </div>
                <div class="contact-form">
                    <form>
                        <div class="form-group">
                            <label for="exampleInputFullName">Full Name</label>
                            <input type="text" class="form-control" id="exampleInputFullName" placeholder="fill your full name">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail">Email</label>
                            <input type="email" class="form-control" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="fill your email address">
                          </div>
                        <div class="form-group">
                            <label for="exampleInputPhone">Phone Number</label>
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                <div class="input-group-text">+65</div>
                                </div>
                                <input type="phone" class="form-control" id="inlineFormInputGroup" placeholder="fill your phone number">
                            </div>
                        </div>
                        <div class="text-lg-right">
                            <button type="submit" class="btn btn-s-main">Submit <fa-icon [icon]="faLongArrowAltRight"></fa-icon></button>
                        </div>
                      </form>
                </div>
            </div>
        </div>
    </div>
</section>