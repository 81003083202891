import { Component, OnInit } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import  faqsJson from  'src/app/shared/jsons/faq.json';

@Component({
  selector: 'fp-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faqs = faqsJson;
  // faChevronDown = faChevronDown;
  // faChevronUp = faChevronUp;
  icon  = faChevronDown;
  isCollapsed = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleIcon() {
    // this.isCollapsed = !this.isCollapsed;
    // if(this.isCollapsed) {
    //   this.icon = faChevronUp;
    // } else {
    //   this.icon = faChevronDown;
    // }
  }

}
