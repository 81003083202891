<section class="agent-banner">

</section>

<section class="agent-profile p-0">
    <div class="wrapper">
        <div class="container">
            <div class="d-sm-flex align-items-end text-sm-left text-center">
                <img class="agent-portrait mr-sm-5" src="/assets/agents/agent.jpg" alt="">
                <div class="content mt-3">
                    <h1 class="d-inline">John Doe</h1>
                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce mollis, tortor ac vestibulum
                        suscipit.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="agent-detail pt-md-5 pt-0">
    <div class="wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 order-lg-1 order-2 mt-lg-0 mt-5">
                    <div class="detail-about">
                        <h3>About Me</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ac arcu arcu. Praesent et tortor
                            hendrerit, efficitur est id, tristique lorem. Donec maximus massa vitae diam rhoncus fermentum.
                            Quisque eleifend arcu augue, non convallis ex tincidunt non. Curabitur semper gravida arcu sit
                            amet convallis. Nunc turpis lorem, cursus id condimentum sit amet, scelerisque vel metus.
                            Phasellus molestie convallis ullamcorper. Morbi accumsan, est eget accumsan faucibus, nulla ex
                            euismod enim, non efficitur nulla ante quis nibh. Vivamus ac justo at orci tristique sodales vel
                            sit amet mi. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </p>
                        <h6>Experience</h6>
                        <ul class="ml-5">
                            <li>
                                Nulla varius congue feugiat. 
                            </li>
                            <li>
                                Nulla dictum, leo a sagittis elementum, dui lacus tempor lectus, etefficitur dui metus et arcu. 
                            </li>
                            <li>
                                Curabitur mattis viverra risus non cursus. 
                            </li>
                            <li>
                                Nunc volutpat, lectus et interdum condimentum, tellus mi ornare urna, at tincidunt nibh nunc ac sapien. 
                            </li>
                            <li>
                                Donec dictum quam a hendrerit iaculis. 
                            </li>
                        </ul>
                        <p>
                            Pellentesque blandit posuere mi, sed imperdiet augue porttitor sed. Morbi egestas fringilla
                            nulla, eget convallis diam consequat non. Praesent nec libero vel sem pharetra cursus. Nulla
                            dapibus justo ac ex mollis dapibus. Nam quis rhoncus risus, nec placerat lacus. Sed id leo vel
                            massa dignissim elementum in sit amet dolor. Donec facilisis nisl vel leo lobortis, nec lobortis
                            nisl feugiat. Cras et ipsum sed justo venenatis ornare et vel justo. In molestie tempus leo ac
                            fringilla.
                        </p>
                    </div>
                    <div class="detail-listing mt-5">
                        <h3 class="mb-4">House Listing</h3>
                        <div class="row">
                            <div class="col-xl-4 col-sm-6 col-12 mb-4" *ngFor="let property of properties">
                                <fp-property-item [property]="property"></fp-property-item>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 order-lg-2 order-1 text-sm-left text-center sticky-lg-top">
                    <div class="sticky-contact sticky-top">
                        <h5 class="title d-none d-sm-block mt-sm-4 mt-md-0 txt-secondary">Contact Now !</h5>
                        <div class="contact mt-lg-4">
                            <a class="d-block link-p-main" href="#">
                                <fa-icon [icon]="faPhoneAlt"></fa-icon>
                                <span>+651234567890</span>
                            </a>
                            <a class="d-block link-p-main" href="#">
                                <fa-icon [icon]="faEnvelope"></fa-icon>
                                <span>john_doe@email.com</span>
                            </a>
                            <a class="d-block link-p-main" href="#">
                                <fa-icon [icon]="faLinkedin"></fa-icon>
                                <span>www.linkedin.com/in/john-doe/</span>
                            </a>
                        </div>
                        <a class="mt-3 whatsapp-btn btn px-lg-3 py-1 px-2 w-100 title">
                            <fa-icon [icon]="faWhatsapp"></fa-icon> 
                            <span>Contact via Whatsapp</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
