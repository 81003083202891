<section class="home-articles container-fluid">
    <div class="wrapper">
        <header class="d-flex justify-content-between align-items-start">
            <h4 class="mb-30 text-font">Recent Articles</h4>
            <a class="link-s-main" routerLink="/news">View All <fa-icon [icon]="faLongArrowAltRight"></fa-icon></a>
        </header>
        <div class="article-list-item row">
            <div class="col-lg-4 col-sm-6 col-12 mb-3">
                <div class="article-card">
                    <img class="article-img" src="https://assets.weforum.org/article/image/large_15gAQdddPzO5Cn18bJ--4zQTUHrfpVxWQbAQ6jhvTSc.jpg" alt="">
                    <h5 class="title">Gravida aliquam vitae aliquam elementum</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl, in adipiscing morbi maecenas vel dolor amet, arcu augue. Neque pharetra consectetur nec libero vitae odio sem consequat. </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12 mb-3">
                <div class="article-card">
                    <img class="article-img" src="https://www.toronto-criminal-lawyer.co/wp-content/uploads/2019/09/Remediation-Agreement-672x372.jpeg" alt="">
                    <h5 class="title">Gravida aliquam vitae aliquam elementum</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl, in adipiscing morbi maecenas vel dolor amet, arcu augue. Neque pharetra consectetur nec libero vitae odio sem consequat. </p>
                </div>
            </div>
            <div class="col-lg-4 col-12 mb-3">
               <div class="article-item d-flex align-items-center">
                    <img class="article-img" src="https://images.theconversation.com/files/213762/original/file-20180409-176974-mulfwg.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=900.0&fit=crop" alt="">
                    <header>
                        <h5 class="title">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique a amet praesent.</h5>
                        <time class="text-secondary">Mon, 10 May 2021. 05:30PM</time>
                    </header>
               </div>
               <div class="article-item d-flex align-items-center">
                    <img class="article-img" src="https://images.unsplash.com/photo-1501183638710-841dd1904471?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="">
                    <header>
                        <h5 class="title">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique a amet praesent.</h5>
                        <time class="text-secondary d-lg-none d-block">Wed, 19 May 2021. 05:30PM</time>
                    </header>
               </div>
               <div class="article-item d-flex align-items-center">
                    <img class="article-img" src="https://images.unsplash.com/photo-1449844908441-8829872d2607?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="">
                    <header>
                        <h5 class="title">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique a amet praesent.</h5>
                        <time class="text-secondary d-lg-none d-block">Fri, 21 May 2021. 05:30PM</time>
                    </header>
               </div>
            </div>
        </div>
    </div>
</section>