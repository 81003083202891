import { Component, OnInit } from '@angular/core';
import { SwiperComponent } from "swiper/angular";
import SwiperCore, {Navigation, Pagination, Swiper} from 'swiper/core';
import { faChevronRight, faChevronLeft, faLongArrowAltRight, faBed, faBath, faPlusSquare, faMinusSquare} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'fp-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.scss']
})
export class HomeBannerComponent implements OnInit {

  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  faLongArrowAltRight = faLongArrowAltRight;
  faBed = faBed;
  faBath = faBath;

  constructor() { }

  ngOnInit(): void {
  }


}
