<section class="company-milestone">
    <div class="container-fluid">
        <div class="wrapper">
            <div class="row">
                <div class="col-lg-3 text-center" *ngFor="let ml of milestones">
                    <span class="total">{{ml.total}}</span>
                    <h5 class="title">{{ml.title}}</h5>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="company-info">
    <div class="container-fluid">
        <div class="wrapper">
            <div class="company-info-list">
                <div class="row">
                    <div class="col-lg-3" *ngFor="let info of infos">
                        <div class="info-icon">
                            <fa-icon [icon]="info.icon"></fa-icon>
                        </div>
                        <div class="info-content">
                            <h6 class="title mt-3">{{info.title}}</h6>
                            <p class="description"><small>{{info.description}}</small></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="company-info-manual">
                <div class="row align-items-center">
                    <div class="col-12 text-center">
                        <span class="switch-wrapper">
                            <button 
                                (click)="changeState(1)" 
                                class="switch tenant" 
                                [ngClass]="isStateOne ? 'active' : ''">
                                Tenants
                            </button>
                            <button 
                                (click)="changeState(2)"  
                                class="switch landlord" 
                                [ngClass]="isStateOne ? '' : 'active'">
                                Landlords
                            </button>
                            <span [@toggleSwitch]="isStateOne ? 'stateOne' : 'stateTwo'" class="slider"></span>
                        </span>
                    </div>
                    <div class="col-lg-5 text-center">
                        <img class="img-fluid" src="{{manual.image}}" alt="graphic info">
                    </div>
                    <div class="col-xl-6 col-lg-7 offset-xl-1">
                        <div class="manual-content mt-lg-0 mt-5">
                            <h2 class="title">{{manual.title}}</h2>
                            <ol>
                                <li *ngFor="let list of manual.lists">
                                    <span class="circle"></span>
                                    <span class="text">
                                        {{list.item}}
                                    </span>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="learn-more text-center">
                <a routerLink="/about" class="mt-5 btn btn-p-main">Learn More</a>    
            </div>
        </div>
    </div>

</section>