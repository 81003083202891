import { Component, OnInit } from '@angular/core';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'fp-home-calculator',
  templateUrl: './home-calculator.component.html',
  styleUrls: ['./home-calculator.component.scss']
})
export class HomeCalculatorComponent implements OnInit {

  faLongArrowAltRight = faLongArrowAltRight;

  constructor() { }

  ngOnInit(): void {
  }

}
