import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './pages/company/about/about.component';
import { AgentDetailComponent } from './pages/company/agent/agent-detail/agent-detail.component';
import { AgentListComponent } from './pages/company/agent/agent-list/agent-list.component';
import { ContactUsComponent } from './pages/company/contact-us/contact-us.component';
import { FaqComponent } from './pages/company/faq/faq.component';
import { LegalComponent } from './pages/company/legal/legal.component';
import { HomeComponent } from './pages/home/home.component';
import { NewsDetailComponent } from './pages/news/news-detail/news-detail.component';
import { NewsListComponent } from './pages/news/news-list/news-list.component';
import { PropertyDetailComponent } from './pages/property/property-detail/property-detail.component';
import { PropertyListComponent } from './pages/property/property-list/property-list.component';

const routes: Routes = [
  {path:"home", component: HomeComponent},
  {path:"property/detail", component: PropertyDetailComponent},
  {path:"property/list", component: PropertyListComponent},
  {path:"property/list/:category", component: PropertyListComponent},
  {path:"about", component: AboutComponent},
  {path:"contact", component: ContactUsComponent},
  {path:"news/list", component: NewsListComponent},
  {path:"news", component: NewsListComponent},
  {path:"news/detail", component: NewsDetailComponent},
  {path:"agent/list", component: AgentListComponent},
  {path:"agent", component: AgentListComponent},
  {path:"agent/detail", component: AgentDetailComponent},
  {path:"privacy-policy", component: LegalComponent},
  {path:"terms-and-condition", component: LegalComponent},
  {path:"faq", component: FaqComponent},
  { path: '',   redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
