<section class="legal">
    <div class="wrapper">
        <div class="container">
            <h1>{{title}}</h1>
            <hr>
            <p>
                Pellentesque blandit posuere mi, sed imperdiet augue porttitor sed. Morbi egestas fringilla
                nulla, eget convallis diam consequat non. Praesent nec libero vel sem pharetra cursus. Nulla
                dapibus justo ac ex mollis dapibus. Nam quis rhoncus risus, nec placerat lacus. Sed id leo vel
                massa dignissim elementum in sit amet dolor. Donec facilisis nisl vel leo lobortis, nec lobortis
                nisl feugiat. Cras et ipsum sed justo venenatis ornare et vel justo. In molestie tempus leo ac
                fringilla.
            </p>
            <ol>
                <li class="title-font">
                    <span class="title">Lorem ipsum dolor sit</span>
                    <ol class="text-font">
                        <li>
                            Nulla varius congue feugiat. 
                        </li>
                        <li>
                            Nulla dictum, leo a sagittis elementum, dui lacus tempor lectus, etefficitur dui metus et arcu. 
                        </li>
                        <li>
                            Curabitur mattis viverra risus non cursus. 
                        </li>
                        <li>
                            Nunc volutpat, lectus et interdum condimentum, tellus mi ornare urna, at tincidunt nibh nunc ac sapien. 
                        </li>
                        <li>
                            Donec dictum quam a hendrerit iaculis. 
                        </li>
                    </ol>
                </li>
                <li class="title-font">
                    <span class="title">Lorem ipsum dolor sit</span>
                    <ol class="text-font">
                        <li>
                            Nulla varius congue feugiat. 
                        </li>
                        <li>
                            Nulla dictum, leo a sagittis elementum, dui lacus tempor lectus, etefficitur dui metus et arcu. 
                        </li>
                        <li>
                            Curabitur mattis viverra risus non cursus. 
                        </li>
                        <li>
                            Nunc volutpat, lectus et interdum condimentum, tellus mi ornare urna, at tincidunt nibh nunc ac sapien. 
                        </li>
                        <li>
                            Donec dictum quam a hendrerit iaculis. 
                        </li>
                    </ol>
                </li>
                <li class="title-font">
                    <span class="title">Lorem ipsum dolor sit</span>
                    <ol class="text-font">
                        <li>
                            Nulla varius congue feugiat. 
                        </li>
                        <li>
                            Nulla dictum, leo a sagittis elementum, dui lacus tempor lectus, etefficitur dui metus et arcu. 
                        </li>
                        <li>
                            Curabitur mattis viverra risus non cursus. 
                        </li>
                        <li>
                            Nunc volutpat, lectus et interdum condimentum, tellus mi ornare urna, at tincidunt nibh nunc ac sapien. 
                        </li>
                        <li>
                            Donec dictum quam a hendrerit iaculis. 
                        </li>
                    </ol>
                </li>
                <li class="title-font">
                    <span class="title">Lorem ipsum dolor sit</span>
                    <ol class="text-font">
                        <li>
                            Nulla varius congue feugiat. 
                        </li>
                        <li>
                            Nulla dictum, leo a sagittis elementum, dui lacus tempor lectus, etefficitur dui metus et arcu. 
                        </li>
                        <li>
                            Curabitur mattis viverra risus non cursus. 
                        </li>
                        <li>
                            Nunc volutpat, lectus et interdum condimentum, tellus mi ornare urna, at tincidunt nibh nunc ac sapien. 
                        </li>
                        <li>
                            Donec dictum quam a hendrerit iaculis. 
                        </li>
                    </ol>
                </li>
                <li class="title-font">
                    <span class="title">Lorem ipsum dolor sit</span>
                    <ol class="text-font">
                        <li>
                            Nulla varius congue feugiat. 
                        </li>
                        <li>
                            Nulla dictum, leo a sagittis elementum, dui lacus tempor lectus, etefficitur dui metus et arcu. 
                        </li>
                        <li>
                            Curabitur mattis viverra risus non cursus. 
                        </li>
                        <li>
                            Nunc volutpat, lectus et interdum condimentum, tellus mi ornare urna, at tincidunt nibh nunc ac sapien. 
                        </li>
                        <li>
                            Donec dictum quam a hendrerit iaculis. 
                        </li>
                    </ol>
                </li>
                <li class="title-font">
                    <span class="title">Lorem ipsum dolor sit</span>
                    <ol class="text-font">
                        <li>
                            Nulla varius congue feugiat. 
                        </li>
                        <li>
                            Nulla dictum, leo a sagittis elementum, dui lacus tempor lectus, etefficitur dui metus et arcu. 
                        </li>
                        <li>
                            Curabitur mattis viverra risus non cursus. 
                        </li>
                        <li>
                            Nunc volutpat, lectus et interdum condimentum, tellus mi ornare urna, at tincidunt nibh nunc ac sapien. 
                        </li>
                        <li>
                            Donec dictum quam a hendrerit iaculis. 
                        </li>
                    </ol>
                </li>

            </ol>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo dolore unde repellat dolor fuga. Magni earum nisi reprehenderit amet odio quos voluptatum quas rerum, reiciendis iste officiis, quia cupiditate impedit.
            </p>
        </div>
    </div>
</section>