<section class="about pt-0">
    <div class="about-banner">
        
    </div>
    <div class="container">
        <h1 class="my-5">About Us</h1>
        <div class="row">
            <div class="col-lg-8 mb-lg-5 mb-3">
                <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vehicula elit sit amet erat rutrum tincidunt. Aenean eu risus suscipit, viverra mi sit amet, vulputate magna. Vivamus ut sem ac sapien dictum gravida sit amet et dolor. Maecenas in nisi auctor, aliquet nibh sed, blandit velit. Fusce congue, augue id accumsan auctor, ante neque blandit ex, a elementum ipsum sapien at ex. Sed eu condimentum mi. Ut quis leo ut mauris fermentum dapibus.</p>
            </div>
            <div class="col-lg-5 my-lg-5 my-3 order-2 order-lg-1">
                <img class="img-fluid" src="https://images.unsplash.com/photo-1565402170291-8491f14678db?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1004&q=80" alt="housing">
            </div>
            <div class="col-lg-6 offset-lg-1 my-lg-5 mt-3 order-1 order-lg-2">
                <h3 class="mb-3">Title</h3>
                <p>Pellentesque vulputate urna erat, quis congue ligula semper et. Quisque eget facilisis dui. Nulla auctor eros et tempor lacinia. In vitae pretium sapien, quis tempor velit. Phasellus tincidunt nulla ut ultrices vestibulum. Nulla mattis ullamcorper convallis. Donec convallis, orci et mollis commodo, turpis justo hendrerit felis, in iaculis odio eros sed enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris convallis vitae ligula nec accumsan. In sagittis felis vel ornare convallis. Etiam tincidunt sit amet risus eget sagittis. Curabitur eu enim felis. Nullam sit amet ante eu est facilisis interdum sed vitae tellus. Ut quis enim at magna placerat lacinia eu id nulla.</p>
            </div>
            <div class="col-12 my-lg-5 my-3">
                <blockquote class="blockquote">
                    <p class="mb-0">Donec pretium orci et dui lacinia volutpat. Suspendisse aliquam lacus eget lorem congue, eget convallis sapien consequat. Aliquam interdum massa eget tellus aliquet ullamcorper. Suspendisse consequat lectus et congue porttitor. </p>
                    <footer class="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></footer>
                  </blockquote>
            </div>
            <div class="col-lg-6 my-lg-5 mt-3">
                <h3 class="mb-3">Title</h3>
                <p>Pellentesque vulputate urna erat, quis congue ligula semper et. Quisque eget facilisis dui. Nulla auctor eros et tempor lacinia. In vitae pretium sapien, quis tempor velit. Phasellus tincidunt nulla ut ultrices vestibulum. Nulla mattis ullamcorper convallis. Donec convallis, orci et mollis commodo, turpis justo hendrerit felis, in iaculis odio eros sed enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris convallis vitae ligula nec accumsan. In sagittis felis vel ornare convallis. Etiam tincidunt sit amet risus eget sagittis. Curabitur eu enim felis. Nullam sit amet ante eu est facilisis interdum sed vitae tellus. Ut quis enim at magna placerat lacinia eu id nulla.</p>
            </div>
            <div class="col-lg-5 offset-lg-1 my-lg-5 my-3">
                <img class="img-fluid" src="https://images.unsplash.com/photo-1448630360428-65456885c650?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1047&q=80" alt="apartment">
            </div>
        </div>
    </div>
</section>