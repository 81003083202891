<div class="sticky-filter container-fluid sticky-top on-scroll d-none d-md-block" #stickyFilter>
    <div class="wrapper">
        <div class="additional-search-wrapper d-flex flex-column flex-lg-row justify-content-between">
            <div class="additional-search flex-lg-fill order-2 order-lg-1">
                <!-- Additional Search Options -->
                <div class="btn-group" *ngFor="let searchOption of additionalSearchOptions">
                   <fp-dropdown [option]="searchOption"></fp-dropdown>
                </div>
            
            </div>
        </div>
    </div>
</div>
<section class="property-listing container-fluid pt-lg-3">
    <div class="wrapper">
        <fp-breadcrumb></fp-breadcrumb>
        <h1><small class="title">Apartments & Condos For Sale in Central Grove</small></h1>
        <p class="txt-grey mb-30 font-italic">Found 1,000 apartments and condos</p>
        <div class="row">
            <div class="col-xl-3 col-lg-4 col-sm-6 col-12" *ngFor="let property of properties">
                <div class="property-list-item">
                    <fp-property-item [property]="property"></fp-property-item>
                </div>
            </div>
        </div>
    </div>
</section>