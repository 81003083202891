import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fp-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  selectedValue;
  selected = false;
  @Input() customClass='';

  constructor() { }

  ngOnInit(): void {
  }

  @Input() option;

  selectOption(value) {
    this.selected = true;
    this.selectedValue = value;
  }
}
