import { Component, OnInit } from '@angular/core';
import { faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faLongArrowAltRight, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'fp-agent-detail',
  templateUrl: './agent-detail.component.html',
  styleUrls: ['./agent-detail.component.scss']
})
export class AgentDetailComponent implements OnInit {

  faLongArrowAltRight = faLongArrowAltRight;
  faWhatsapp = faWhatsapp;
  faEnvelope = faEnvelope;
  faPhoneAlt = faPhoneAlt;
  faLinkedin = faLinkedin;

  properties = [
    {
      "name": "Midtown Modern",
      "images": [
          {
              "path": "https://enerbi.co.id/wp-content/uploads/2017/12/Ten-Renowned-Green-Buildings-in-India-1000x455.jpg",
              "alt": "house"
          },
          {
              "path": "https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHw%3D&w=1000&q=80",
              "alt": "house"
          },
          {
              "path": "https://static.wixstatic.com/media/16c592_af9b87d4dab84e29b0c6faf04932636d~mv2_d_2975_2081_s_2.jpg",
              "alt": "house"
          }
      ],
      "type": "House",
      "status": "Rent",
      "country":"singapore",
      "city":"Ang Mo Kio New Town",
      "address": "tan quee lan street d07",
      "price": "$ 1,1 M",
      "bedroom": "3",
      "bathroom": "3",
      "area": "1,097 sqft",
      "featured": 0
  },
  {
      "name": "Emerald Hill Conservation",
      "images": [
          {
              "path": "https://images.unsplash.com/photo-1480074568708-e7b720bb3f09?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1353&q=80",
              "alt": "house"
          },
          {
              "path": "https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHw%3D&w=1000&q=80",
              "alt": "house"
          },
          {
              "path": "https://static.wixstatic.com/media/16c592_af9b87d4dab84e29b0c6faf04932636d~mv2_d_2975_2081_s_2.jpg",
              "alt": "house"
          }
      ],
      "type": "House",
      "status": "Rent",
      "country":"singapore",
      "city":"Ayer Raja New Town",
      "address": "tan quee lan street d07",
      "price": "$ 1,1 M",
      "bedroom": "3",
      "bathroom": "3",
      "area": "1,097 sqft",
      "featured": 0
  },
  {
      "name": "Ang Mo Kio Avenue 4",
      "images": [
          {
              "path": "https://images.unsplash.com/photo-1601628828688-632f38a5a7d0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1211&q=80"
          },
          {
              "path": "https://enerbi.co.id/wp-content/uploads/2017/12/Ten-Renowned-Green-Buildings-in-India-1000x455.jpg",
              "alt": "house"
          },
          {
              "path": "https://images.unsplash.com/photo-1583847268964-b28dc8f51f92?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=634&q=80",
              "alt": "house"
          }
      ],
      "type": "House",
      "status": "Rent",
      "country":"singapore",
      "city":"Bedok New Town",
      "address": "tan quee lan street d07",
      "price": "$ 1,1 M",
      "bedroom": "3",
      "bathroom": "3",
      "area": "1,097 sqft",
      "featured": 0
  }
  ]
  
  constructor() { }

  ngOnInit(): void {
  }

}
