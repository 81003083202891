<section class="section-banner">
    <div class="wrapper">
        <div class="container-fluid">
            <h1 class="title text-white text-center my-5">Meet Our Agents</h1>
        </div>
    </div>
</section>
<section class="agent-list">
    <div class="container">
        <div class="wrapper">
            <div class="row">
                <div class="col-lg-6 col-12 my-5 agent" *ngFor="let agent of agents">
                    <div class="row">
                        <div class="col-5"><img class="agent-portrait" [src]="agent.portrait" [alt]="agent.name"></div>
                        <div class="col-7">
                            <h4>{{agent.name}}</h4>
                            <hr>
                            <p>{{agent.introduction}}</p>
                            <a class="link-s-main" routerLink="/agent/detail">See full profile <fa-icon [icon]="faLongArrowAltRight"></fa-icon></a>
                            <p class="mt-3 click"><fa-icon [icon]="faPhoneAlt"></fa-icon> +{{agent.contact}}</p>
                            <div class="contact d-flex justify-content-start align-items-center">
                                <a class="btn out-p-main py-1 px-2 mr-2" href="#">
                                    <fa-icon [icon]="faWhatsapp"></fa-icon>
                                </a>
                                <a class="btn out-p-main py-1 px-2  mr-2" href="#">
                                    <fa-icon [icon]="faEnvelope"></fa-icon>
                                </a>
                                <a class="btn out-p-main py-1 px-2  mr-2" href="#">
                                    <fa-icon [icon]="faLinkedin"></fa-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>