<header>
  <div class="wrapper">
    <div class="social-nav container-fluid">
      <!-- Email -->
      <div class="email float-left">
        <fa-icon [icon]="faEnvelope"></fa-icon>
        <span>sales@firstproperty.com</span>
      </div>

      <!-- Social Media -->
      <div class="social-media float-right">
        <div class="d-flex">
          <a href="#" class="social-media-item">
            <fa-icon [icon]="faTwitter"></fa-icon>
          </a>
          <a href="#" class="social-media-item">
            <fa-icon [icon]="faFacebookF"></fa-icon>
          </a>
          <a href="#" class="social-media-item">
            <fa-icon [icon]="faInstagram"></fa-icon>
          </a>
          <a href="#" class="social-media-item">
            <fa-icon [icon]="faWhatsapp"></fa-icon>
          </a>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <div class="navbar-wrapper">
    <nav class="navbar navbar-expand-lg navbar-light wrapper">
      <a class="navbar-brand" routerLink="/home"><img class="img-fluid" src="assets/logo.png" alt=""></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHeader"
        aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarHeader">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" data-toggle="collapse" data-target="#navbarHeader" routerLink="/home">Home <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target="#navbarHeader" routerLink="/property/list">Buy</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target="#navbarHeader" routerLink="/property/list">Rent</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target="#navbarHeader" routerLink="/calculator">Mortgage</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target="#navbarHeader" routerLink="/agent/list">Find Agents</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target="#navbarHeader" routerLink="/news/list">News</a>
          </li>
          <li class="nav-item d-lg-none d-block">
            <a class="nav-link" data-toggle="collapse" data-target="#navbarHeader" routerLink="/contact">Contact Us</a>
          </li>

        </ul>
        <a routerLink="/contact" class="btn btn-s-main d-none d-lg-block">Contact Us</a>
      </div>
    </nav>
  </div>
</header>
