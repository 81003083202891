import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cityFilter',
  pure: false
})
export class CityPipe implements PipeTransform {

  transform(value: any, input: any): any {
    if (input) {
        const select = value.filter(val => ((val).toLowerCase( )).indexOf(input.toLowerCase()) >= 0);
        if(select) {
            return select;
        } else {
            return "not found";
        }
    } else {
      return value;
    }
  
  }

}
