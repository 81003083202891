<!------------ Home Banner ------------>
<fp-home-banner></fp-home-banner>

<!------------ Home Search ------------>
<fp-home-search></fp-home-search>

<!------------ Home Category ------------>
<fp-home-category></fp-home-category>

<!-- Home Listing -->
<fp-home-listing></fp-home-listing>

<!-- Home Listing -->
<fp-home-listing></fp-home-listing>

<!-- Home Info -->
<fp-home-info></fp-home-info>

<!-- Home Listing -->
<fp-home-listing></fp-home-listing>

<!-- Home Listing -->
<fp-home-listing></fp-home-listing>

<!-- Home Listing -->
<fp-home-calculator></fp-home-calculator>

<!-- Home Articles -->
<fp-home-articles></fp-home-articles>

<!-- Home Contacts -->
<fp-contact-us></fp-contact-us>