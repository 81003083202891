import { Component, OnInit, ViewChild } from '@angular/core';
import Swiper from 'swiper';
import  propertiesJson  from  'src/app/shared/jsons/properties.json';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'fp-home-listing',
  templateUrl: './home-listing.component.html',
  styleUrls: ['./home-listing.component.scss']
})
export class HomeListingComponent implements OnInit {
    @ViewChild('swiperListing') swiperListing: any;
  
    config: any = {
        slidesPerView: 'auto',
        spaceBetween: 20,
        breakpoints: {
            1200: {
                spaceBetween: 20,
                slidesPerView: 4
            }
        }
    }

    properties = propertiesJson;
    faLongArrowAltRight = faLongArrowAltRight;

    constructor() {
    }

    ngOnInit(): void {
    }

}
