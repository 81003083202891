import { Component, OnInit } from '@angular/core';
import SwiperCore from 'swiper/core';

@Component({
  selector: 'fp-property-detail-banner',
  templateUrl: './property-detail-banner.component.html',
  styleUrls: ['./property-detail-banner.component.scss']
})
export class PropertyDetailBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
