import { Component, OnInit } from '@angular/core';
import articlesJson from 'src/app/shared/jsons/articles.json';

@Component({
  selector: 'fp-news',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  articles = articlesJson;

}
