<footer>
  <div class="footer-wrapper wrapper">
    <div class="container-fluid">
      <div class="row">
        <!-- Company Info -->
        <div class="col-lg-3 company-info">
          <div class="footer-brand">
            <img src="assets/logo-white.png" alt="logo white first property">
          </div>
          <p class="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis enim, eget rhoncus, sit.
            Consequat donec tellus diam vulputate sed. Proin sed pretium gravida leo, massa viverra arcu. Amet est
            suspendisse in ornare
            aliquet. </p>
        </div>
        <!-- Company Contact -->
        <div class="col-xl-3 col-lg-4">
          <ul class="list-unstyled company-contact">
            <li>
              <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
              <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mauris commodo </span>
            </li>
            <li>
              <fa-icon [icon]="faPhone"></fa-icon>
              <span>+65 123 456 789</span>
            </li>
            <li>
              <fa-icon [icon]="faPhone"></fa-icon>
              <span>+65 123 456 789</span>
            </li>
          </ul>
        </div>

        <div class="offset-xl-1"></div>

        <!-- Company Links -->
        <div class="col-lg-3 company-links links">
          <h6 class="title">Company</h6>
          <ul class="list-unstyled">
            <li routerLink="/about" class="link-item">About</li>
            <li routerLink="/news" class="link-item">News</li>
            <li routerLink="/contact" class="link-item">Contact Us</li>
            <li routerLink="/faq" class="link-item">FAQ</li>
            <li routerLink="/terms-and-condition" class="link-item">Terms & Condition</li>
            <li routerLink="/privacy-policy" class="link-item">Privacy Policy</li>
          </ul>
        </div>
        <!-- Discover Links -->
        <div class="col-lg-2 discover-links links">
          <h6 class="title">Discover</h6>
          <ul class="list-unstyled">
            <li routerLink="/property/list/condominium" class="link-item">Condos</li>
            <li routerLink="/property/list/apartment" class="link-item">Apartment</li>
            <li routerLink="/property/list/housing" class="link-item">Housing</li>
            <li routerLink="/property/list/townhouse" class="link-item">Townhouse</li>
          </ul>
        </div>
        <!-- Copyright -->
        <div class="col-12 copyright">
          <div class="d-lg-flex d-block justify-content-between align-items-end text-lg-left text-center">
            <p class="mb-lg-0">Copyright 2021 &copy; First Property. All Rights Reserved. Developed & Powered by <a
                class="folxcode" href="https://www.folxcode.com">Folxcode</a></p>
            <div class="social-media">
              <span class="social-media-item">follow us</span>
              <a class="social-media-item">
                <fa-icon [icon]="faTwitter"></fa-icon>
              </a>
              <a class="social-media-item">
                <fa-icon [icon]="faFacebookF"></fa-icon>
              </a>
              <a class="social-media-item">
                <fa-icon [icon]="faInstagram"></fa-icon>
              </a>
              <a class="social-media-item">
                <fa-icon [icon]="faWhatsapp"></fa-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
