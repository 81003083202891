<section class="prop-detail-banner">
    <div class="dashed-line"></div>
    <swiper
    [slidesPerView]="'auto'"
    [spaceBetween]="20"
    [navigation]="true"
    >
    <ng-template swiperSlide>
        <img src="/assets/properties/house-10.jpg" alt="house">
    </ng-template>
    <ng-template swiperSlide>
        <img src="/assets/properties/house-4.jpg" alt="house">
    </ng-template>
    <ng-template swiperSlide>
        <img src="/assets/properties/house-8.jpg" alt="house">
    </ng-template>
    <ng-template swiperSlide>
        <img src="/assets/properties/house-11.jpg" alt="house">
    </ng-template>
    <ng-template swiperSlide>
        <img src="/assets/properties/house-13.jpg" alt="house">
    </ng-template>
    <ng-template swiperSlide>
        <img src="/assets/properties/house-17.jpg" alt="house">
    </ng-template>
    </swiper>
    <div class="dashed-line"></div>
    <!-- <div class="custom-swiper-wrapper">
    </div> -->
</section>