import { Component, OnInit } from '@angular/core';
import { faPlusSquare, faMinusSquare, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { CityService } from 'src/app/core/services/city.service';
import { FormControl } from '@angular/forms';

import  searchOptionsJson  from  'src/app/shared/jsons/searchOptions.json';

@Component({
  selector: 'fp-home-search',
  templateUrl: './home-search.component.html',
  styleUrls: ['./home-search.component.scss']
})
export class HomeSearchComponent implements OnInit {

  // Toggle Advance Search
  hidden = false;
  toggleText="More Search Options";
  toggleIcon = faPlusSquare;
  toggleInput = false;
  selectedValue;
  cities;
  searchCity;
  selectedCity = new FormControl('city');
  faTimesCircle = faTimesCircle;
  resetBtnShown = false;

  // Additional Search Options Data
  additionalSearchOptions = searchOptionsJson;

  // Status options
  statusOption = {
    type:"Status",
    list: [
      {
        name:"For Rent",
        value: 1
      },
      {
        name:"For Sell",
        value: 2
      }
    ]
  }

  constructor(private cityService: CityService) { }

  ngOnInit(): void {
    this.cityService.getCities()
    .subscribe((data) => {
      this.cities = data['data'];
    }, error => {
      console.log(error);
    });
  }

  ngOnChanges() {
    if(this.selectedCity.value) {
      this.resetBtnShown = true;
    }
  }

  
  toggleAddSearch() {
    this.hidden = !this.hidden;
    if(this.hidden) {
      this.toggleIcon = faPlusSquare;
      this.toggleText = "More Search Options";
    } else {
      this.toggleIcon = faMinusSquare;
      this.toggleText = "Less Search Options"
    }
  }

  toggleSearchInput(event) {
    this.toggleInput = true;
  }

  selectCity(city) {
    // console.log(city);
    this.selectedCity.setValue(city);
    this.toggleInput = false;
    if(this.selectedCity.value) {
      this.resetBtnShown = true;
    }
  }

  resetFilter() {
    this.selectedCity.setValue(null);
    this.resetBtnShown = false;
  }

}
