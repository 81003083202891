<fp-property-detail-banner></fp-property-detail-banner>
<div class="sticky-info d-lg-none d-block" [@showHide]="isShow ? 'show' : 'hide'" >
    <div class="container-fluid">
        <div class="wrapper">
            <p class="mb-0 property-price mb-2 pb-2">Property Price : <span class="price">$1,198,888</span></p>
            <div class="agent-mobile">
                <div class="agent-profile d-flex">
                    <img class="agent-image mr-3" src="https://randomuser.me/api/portraits/women/27.jpg" width="50" height="50" alt="agent name">
                    <div class="agent-info">
                        <h6 class="text-font mb-1">Alexandra</h6>
                        <span class="txt-secondary">First Property Agent</span>
                    </div>
                </div>
                <button class="btn btn-s-main btn-contact px-3 py-2">
                    <span class="d-none d-sm-inline">CONTACT NOW</span>
                    <fa-icon class="d-inline d-sm-none"  [icon]="faPhoneAlt"></fa-icon>    
                </button>
            </div>
        </div>
    </div>
</div>
<main class="container-fluid">
    <article class="wrapper">
        <div class="row">
            <div class="col-xl-8 col-lg-7">
                <fp-property-detail-main></fp-property-detail-main>
            </div>
            <div class="col-xl-4 col-lg-5" #propertyAside>
                <div class="h-100 pt-lg-0 pt-3" style="background-color: #FFF;">
                    <fp-property-detail-aside></fp-property-detail-aside>
                </div>
            </div>
        </div>
    </article>
</main>