<section class="home-category container-fluid">
    <div class="wrapper">
        <h4 class="mb-30 text-font">Category</h4>
        <swiper
            [slidesPerView]="'auto'"
            [spaceBetween]="20"
            [navigation]="true"
        >
            <ng-template swiperSlide *ngFor="let category of categories">
                <a [routerLink]="category.slug" class="hoverable">
                    <img [src]="category.image" [alt]="category.title">
                    <div class="dark-overlay">
                        <h4 class="text title-font title">
                            {{category.title}}
                        </h4>
                    </div>
                </a>
            </ng-template>
            
        </swiper>
    </div>
</section>
