<section class="property-detail-aside">
    <div class="agent container-fluid">
        <div class="agent-profile d-flex">
            <img class="agent-image mr-3" src="https://randomuser.me/api/portraits/women/27.jpg" width="50" height="50" alt="agent name">
            <div class="agent-info">
                <h5 class="text-font">Alexandra</h5>
                <span class="txt-secondary">First Property Agent</span>
            </div>
        </div>
        <button class="btn btn-s-main btn-contact">CONTACT NOW</button>
    </div>
    <hr>
    <div class="property-price container-fluid">
        <h5 class="txt-p-light">Property Price</h5>
        <h6>
            <small class="font-weight-bold text-font">
                <span class="price">$1,198,888</span>
                <span class="price-bg"></span>
            </small>
        </h6>
    </div>
    <hr>
    <div class="calculators accordion" id="calculatorAccordion">
        <div class="m-calc">
            <p class="title click collapsed container-fluid" data-toggle="collapse" data-target="#collapseMCalc" aria-controls="collapseOne">Mortgage Calculator <fa-icon [icon]="faChevronDown"></fa-icon></p>
            <div id="collapseMCalc" class="collapse show" aria-labelledby="calculatorMortgage" data-parent="#calculatorAccordion">
                <hr>
                <form class="container-fluid">
                    <div class="form-group">
                      <label class="txt-p-light" for="inputTenure">Tenure</label>
                      <input type="number" class="form-control" id="staticTenure" value="30">
                      <label class="unit" for="unitTenure">YEARS</label>
                    </div>
                    <div class="form-group">
                      <label class="txt-p-light" for="inputLoan">Loan</label>
                      <input type="number" class="form-control" value="72" id="inputLoan">
                      <label class="unit" for="unitLoan">%</label>
                    </div>
                    <div class="form-group">
                      <label class="txt-p-light" for="inputInterest">Interest</label>
                      <input type="number" class="form-control" value="2" id="inputInterest">
                      <label class="unit" for="unitInterest">%</label>
                    </div>
                    <h6 class="title txt-secondary mt-4">Monthly Payments Est.</h6>
                    <div class="form-group">
                        <label class="font-weight-bold dollar" for="inputInterest">$</label>
                        <input type="number" class="form-control" id="inputResult" value="381">
                        <label class="unit font-weight-bold txt-grey m-0" for="unitInterest">/month</label>
                    </div>

                </form>
            </div>
        </div>

        <hr>
        <div class="aff-calc">
            <p class="title click collapsed container-fluid" data-toggle="collapse" data-target="#collapseACalc" aria-expanded="false" aria-controls="collapseACalc">Affordability Calculator <fa-icon [icon]="faChevronDown"></fa-icon></p>

            <div id="collapseACalc" class="collapse" aria-labelledby="calculatorAffordability" data-parent="#calculatorAccordion">
                <hr>
                <form class="container-fluid">
                    <div class="form-group">
                      <label class="txt-p-light" for="inputTenure">Tenure</label>
                      <input type="number" class="form-control" id="staticTenure" value="30">
                      <label class="unit" for="unitTenure">YEARS</label>
                    </div>
                    <div class="form-group">
                      <label class="txt-p-light" for="inputLoan">Loan</label>
                      <input type="number" class="form-control" value="72" id="inputLoan">
                      <label class="unit" for="unitLoan">%</label>
                    </div>
                    <div class="form-group">
                      <label class="txt-p-light" for="inputInterest">Interest</label>
                      <input type="number" class="form-control" value="2" id="inputInterest">
                      <label class="unit" for="unitInterest">%</label>
                    </div>
                    <h5 class="title txt-secondary mt-4">Monthly Payments Est.</h5>
                    <div class="form-group">
                        <label class="font-weight-bold dollar" for="inputInterest">$</label>
                        <input type="number" class="form-control" id="inputResult" value="381">
                        <label class="unit font-weight-bold txt-grey m-0" for="unitInterest">/month</label>
                    </div>

                </form>
            </div>
        </div>

        <hr>
        <div class="pp-calc">
            <p class="title click collapsed container-fluid" data-toggle="collapse" data-target="#collapsePPCalc" aria-expanded="false"  aria-controls="collapsePPCalc">Progressive Payments Calculator <fa-icon [icon]="faChevronDown"></fa-icon></p>

            <div id="collapsePPCalc" class="collapse" aria-labelledby="collapsePPCalc" data-parent="#calculatorAccordion">
                <hr>
                <form class="container-fluid">
                    <div class="form-group">
                      <label class="txt-p-light" for="inputTenure">Tenure</label>
                      <input type="number" class="form-control" id="staticTenure" value="30">
                      <label class="unit" for="unitTenure">YEARS</label>
                    </div>
                    <div class="form-group">
                      <label class="txt-p-light" for="inputLoan">Loan</label>
                      <input type="number" class="form-control" value="72" id="inputLoan">
                      <label class="unit" for="unitLoan">%</label>
                    </div>
                    <div class="form-group">
                      <label class="txt-p-light" for="inputInterest">Interest</label>
                      <input type="number" class="form-control" value="2" id="inputInterest">
                      <label class="unit" for="unitInterest">%</label>
                    </div>
                    <h5 class="title txt-secondary mt-4">Monthly Payments Est.</h5>
                    <div class="form-group">
                        <label class="font-weight-bold dollar" for="inputInterest">$</label>
                        <input type="number" class="form-control" id="inputResult" value="381">
                        <label class="unit font-weight-bold txt-grey m-0" for="unitInterest">/month</label>
                    </div>

                </form>
            </div>
        </div>
    </div>
</section>