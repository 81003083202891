<section class="property-listing container-fluid">
    <div class="wrapper">
        <header class="d-flex justify-content-between align-items-start">
            <h4 class="mb-30 text-font">House Listing</h4>
            <a class="link-s-main" routerLink="/property/list">View All <fa-icon [icon]="faLongArrowAltRight"></fa-icon></a>
        </header>
        <swiper
            #swiperListing
            [config]="config"
        >
              <ng-template swiperSlide  *ngFor="let property of properties">
                <div class="property-list-item">
                    <fp-property-item [property]="property"></fp-property-item>
                </div>
              </ng-template>
        </swiper>
    </div>
</section>