<section class="article-detail container-fluid">
    <div class="wrapper">
        <header class="detail-heading">
            <h1 class="title">Placeat fugit, non, quo impedit quia fuga similique cupiditate vitae cum, veniam asperiores labore. </h1>
            <p class="mb-2 txt-grey"><em>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Libero voluptas asperiores fuga nostrum, qui voluptatum repudiandae ipsum saepe totam odio accusamus.</em></p>
            <div class="d-flex justify-content-between align-items-center">
                <time class="text-secondary">Wed, 19 May 2021. 05:30PM</time>
                <!-- <div class="d-inline share-media">
                    <span class="txt-grey mr-2">Share to :</span>
                    <fa-icon [icon]="faTwitter"></fa-icon>
                    <fa-icon [icon]="faFacebookF"></fa-icon>
                    <fa-icon [icon]="faInstagram"></fa-icon>
                    <fa-icon [icon]="faWhatsapp"></fa-icon>
                    <fa-icon [icon]="faEnvelope"></fa-icon>
                    <fa-icon [icon]="faLink"></fa-icon>
                </div> -->
            </div>
        </header>
        <div class="detail-content">
            <div class="article-image text-center">
                <figure class="figure my-5">
                    <img src="https://images.unsplash.com/photo-1494526585095-c41746248156?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80" class="figure-img img-fluid rounded" alt="title">
                    <figcaption class="figure-caption">Image Lorem ipsum dolor sit amet consectetur adipisicing elit</figcaption>
                </figure>
            </div>
            <p style="white-space: pre-wrap">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce mollis, tortor ac vestibulum suscipit, odio quam porta mauris, rhoncus bibendum velit arcu non sapien. Curabitur vulputate auctor viverra. Vestibulum ut leo id purus pulvinar imperdiet a ultricies libero. Duis et nibh nec sem placerat interdum. Cras in malesuada est. Aenean in tincidunt justo. Praesent condimentum varius neque at tincidunt. Sed ultrices, nisl ut sollicitudin interdum, tellus augue maximus neque, a mollis diam libero et justo. Vestibulum ut tortor elit.
                <br/>
                <br/>
                Donec sagittis diam sit amet ex mollis, sit amet dignissim orci molestie. Nulla bibendum elementum odio, ut interdum diam vehicula et. Nulla semper, velit eu hendrerit accumsan, ligula tortor porttitor justo, vitae dignissim est purus lacinia urna. Mauris sed nibh eros. Morbi sapien mi, vestibulum non placerat ut, condimentum et enim. Donec tristique quis nulla vitae lacinia. Maecenas turpis nunc, commodo at tristique eget, consequat at lorem. Aliquam leo dui, placerat ut nulla suscipit, luctus imperdiet libero. Curabitur euismod consectetur risus, vitae commodo dolor sodales vitae. Maecenas sed lectus pharetra, pretium turpis id, dictum diam. Nulla faucibus tincidunt felis consequat aliquam. Morbi scelerisque tortor tempus est consequat, sit amet tincidunt diam hendrerit. Phasellus ac sapien nulla. Curabitur eget dictum massa. Cras vel justo lobortis, posuere purus at, mollis elit.
                <br/>
                <br/>
                Nam sodales placerat efficitur. Nunc at viverra magna, scelerisque placerat justo. Donec feugiat, turpis ut dignissim varius, justo orci facilisis massa, vitae vulputate lectus nibh vitae velit. Suspendisse potenti. Nam porttitor cursus eros. Quisque sed mollis ipsum, id tincidunt urna. Nulla facilisi. Nulla facilisi. Pellentesque bibendum, tellus bibendum tristique malesuada, diam arcu efficitur turpis, sed dictum ex urna ut ipsum. Proin sollicitudin augue in arcu condimentum ultricies. Proin faucibus vel leo nec pellentesque. Donec commodo accumsan risus, finibus eleifend diam varius non. Nam sit amet nibh ac justo vehicula vehicula fermentum et felis.
                <br/>
                <br/>
                Fusce eget dui et urna hendrerit egestas. Aenean semper diam non augue dapibus placerat. Integer vitae finibus ante. Vivamus felis eros, commodo vel dignissim ut, vestibulum in metus. Phasellus et nibh nulla. Sed turpis ligula, malesuada ac neque malesuada, porta porttitor tellus. Curabitur dignissim volutpat urna, at aliquam lectus commodo eu. Aliquam ac volutpat sapien, sit amet consequat massa. Morbi egestas, lorem sed malesuada luctus, erat lectus dignissim elit, sit amet hendrerit erat arcu et orci. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vivamus posuere ligula id mauris gravida, ut maximus massa hendrerit. Phasellus lacinia mollis ex, sed lobortis turpis commodo vel. Donec auctor bibendum ante at fringilla.
                <br/>
                <br/>
                Ut metus justo, scelerisque vitae maximus sed, imperdiet quis ipsum. Sed ex magna, condimentum sodales dapibus non, commodo sed dui. Nunc fermentum eu odio et bibendum. Etiam leo dolor, convallis molestie ultrices in, ultricies vel felis. Sed ac lorem sit amet diam iaculis varius. Nullam feugiat blandit hendrerit. Aenean dapibus arcu rutrum, efficitur libero ac, ullamcorper tortor. Quisque ut ante vestibulum, pulvinar mi iaculis, venenatis eros. Vivamus semper aliquet turpis, vel eleifend nunc suscipit vel. Mauris ornare pulvinar ex sed fringilla. Curabitur condimentum vehicula lacus et eleifend. Integer a sem mollis, scelerisque justo vel, viverra nulla. Donec quis enim nec ante pellentesque pulvinar ac eu nisi. Suspendisse potenti. Curabitur porttitor enim id ex faucibus, quis egestas magna congue.</p>
        </div>
        <div class="detail-footer text-center mt-5">
            <a routerLink="/news/list" class="link-s-main"><fa-icon [icon]="faLongArrowAltLeft"></fa-icon> Back To All Articles</a>
            <!-- <div class="d-flex justify-content-between align-items-center">
                <div class="d-inline share-media">
                   <span class="txt-grey mr-2">Share to :</span>
                    <fa-icon [icon]="faTwitter"></fa-icon>
                    <fa-icon [icon]="faFacebookF"></fa-icon>
                    <fa-icon [icon]="faInstagram"></fa-icon>
                    <fa-icon [icon]="faWhatsapp"></fa-icon>
                    <fa-icon [icon]="faEnvelope"></fa-icon>
                    <fa-icon [icon]="faLink"></fa-icon>
                </div>

            </div> -->
        </div>
    </div>
</section>