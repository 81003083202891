import { Component, OnInit } from '@angular/core';
import { SwiperComponent } from "swiper/angular";
import SwiperCore, {Navigation, Pagination, Swiper} from 'swiper/core';
import { faChevronRight, faChevronLeft, faLongArrowAltRight, faBed, faBath, faPlusSquare, faMinusSquare} from '@fortawesome/free-solid-svg-icons';

SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'fp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
