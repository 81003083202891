<section class="home-search">
    <div class="wrapper">
        <h1 class="title text-center mb-50 container-fluid">Lorem Ipsum Dolor Sit Amet</h1>
        <form class="search-box">
            <div class="input-group mb-3 flex-nowrap">
                <div class="input-group-prepend">
                    <fp-dropdown [option]="statusOption" [customClass]="'input-btn h-100'"></fp-dropdown>
                </div>
                <div class="search-box-form flex-grow-1">
                    <input type="text" class="form-control" aria-label="Where do you want to live?" [placeholder]="'Where do you want to live?'" (click)="toggleSearchInput($event)" [(ngModel)]="searchCity" [formControl]="selectedCity" (keyup)="resetBtnShown=true">
                    <span class="reset-filter click" (click)="resetFilter()" *ngIf="resetBtnShown">
                        <fa-icon [icon]="faTimesCircle"></fa-icon>
                    </span>
                    <div class="search-box-item" *ngIf="toggleInput">
                        <div class="search-box-item-list dropdown-item click" *ngFor="let city of cities | cityFilter: searchCity" (click)="selectCity(city)">{{city}}
                        </div>
                    </div>
                </div>

                <button class="btn btn-p-main ml-3 d-lg-block d-none" type="submit">Search</button>
            </div>
            <div class="additional-search-wrapper d-flex flex-column flex-lg-row justify-content-between">
                <div class="additional-search flex-lg-fill order-2 order-lg-1 d-none d-md-block">
                    <!-- Additional Search Options -->
                    <div class="btn-group" *ngFor="let searchOption of additionalSearchOptions">
                       <fp-dropdown [option]="searchOption"></fp-dropdown>
                    </div>
                </div>
                <!-- Hide & Show Additional Search Options  -->
                <button class="btn px-0 toggle-add-search order-1 order-lg-2 text-left text-lg-center d-block d-md-none" (click)="toggleAddSearch()"><fa-icon [icon]="toggleIcon"></fa-icon> {{toggleText}}</button>
                <div class="clearfix"></div>
            </div>
        </form>
    </div>
</section>