import { Component, HostListener, OnInit } from '@angular/core';
import { faHandPointer } from '@fortawesome/free-solid-svg-icons';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'fp-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss'],
  animations:[
    trigger('showHide', [
      state('show', style({
        right:0
      })),
      state('hide', style({
        right:-200
      })),
      transition('show <=> hide', [
        animate('.3s')
      ]),
    ])
  ]
})

export class ScrollTopComponent implements OnInit {

  faHandPointer = faHandPointer;
  isShow: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:scroll')
  checkScroll() {

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

    if (scrollPosition >= window.innerHeight) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  goToTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

}
